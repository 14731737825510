const navigation = [
  {
    label: 'Společnost',
    key: 'spolecnost',
    href: '/',
    subMenu: [
      { label: 'O nás', href: '/o-nas', key: 'about' },
      { label: 'Často kladené otázky', href: '/faq', key: 'faq' },
    ],
  },
  {
    label: 'Kontakty',
    href: '/kontakt',
    key: 'contact',
  },
]

const profileNavigation = [
  {
    label: 'Přehled',
    key: 'profile-overview',
    href: '/profile/overview',
    icon: 'i-fubex-square-menu',
  },
  {
    label: 'Směna',
    key: 'profile-exchange',
    href: '/profile/exchange',
    icon: 'i-fubex-exchange',
  },
  {
    label: 'Historie směn',
    key: 'profile-history',
    href: '/profile/history',
    icon: 'i-fubex-history',
  },
  {
    label: 'Můj účet',
    href: '/profile/account',
    key: 'profile',
    icon: 'i-fubex-settings',
  },
  {
    label: 'Bonusový program',
    key: 'profile-bonus',
    href: '/profile/bonus',
    icon: 'i-fubex-bonus',
  },
  {
    label: 'Odhlásit se',
    key: 'logout',
    href: '/logout',
    icon: 'i-fubex-logout',
  },
]
const mobileMenuOpen = ref(false)

export default function useNavigation () {
  const prepareNavigation = async () => {}
  return {
    mobileMenuOpen,
    navigation,
    profileNavigation,
    prepareNavigation,
  }
}
